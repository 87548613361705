import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Container, Row, Button } from "react-bootstrap";
import * as Yup from "yup";
import { TextField } from "../formFields/Fields";
import ModalView from "../modalView";
import { toast } from "react-toastify";
import { createTag, updateTag } from "../../utils/endpoints";

const validationSchema = Yup.object({
  tag_name: Yup.string().required("Tag Name is required"),
});

const CreateTags = ({ show, handleClose, obj = null, reload }) => {
  const [initialValues, setInitialValue] = useState({
    tag_name: "",
  });

  useEffect(() => {
    if (obj) {
      setInitialValue({
        tag_name: obj.tag_name || "",
      });
    } else {
      setInitialValue({ tag_name: "" });
    }
  }, [obj]);

  const handleSubmit = async (values) => {
    try {
      if (obj) {
        const data = await updateTag(values, obj.id);
        if(data.error) {
          toast.error(data.error.message);
        } else {
          toast.success("Tag Updated!");
        }
      } else {
        const data = await createTag(values);
        if(data.error) {
          toast.error(data.error.message);
        } else {
          toast.success("Tag Created!");
        }
      }
      reload();
      handleClose();
    } catch {
      toast.error(`Failed ${obj ? "Updation" : "Creation"} of Tag!`);
    }
  };

  return (
    <ModalView
      show={show}
      handleClose={handleClose}
      title={obj ? "Update Tag" : "Create Tag"}
    >
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <Form>
            <Row>
              <TextField name="tag_name" label="Tag Name" />
            </Row>
            <div className="float-right mt-20 d-flex">
              <div className="mr-10">
                <Button
                  onClick={handleClose}
                  variant="secondary"
                >
                  Cancel
                </Button>
              </div>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </div>
          </Form>
        </Formik>
      </Container>
    </ModalView>
  );
};

export default CreateTags;
