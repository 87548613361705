export const tagTypeOption = [{value: 'sub_tag', label: "Sub Tag"}, {value: 'main_tag', label: "Main Tag"}]

export const transformedTagList = (data) => data?.map(item => ({
    value: item.id,
    label: item.tag_name
  }));

  export const transformedSectionList = (data) => data?.map(item => ({
    value: item.id,
    label: item.section_name
  }));

  export const transformedStoryList = (data) => data?.map(item => ({
    value: item.id,
    label: item.title
  }));