import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout";
import { deleteTag, getTagList } from "../../utils/endpoints";
import { Button, Table, Form } from "react-bootstrap";
import actionFormatter from "./actions";
import CreateTag from "./createTag";
import DeleteConfirmation from "../modalView/deleteConfirmation";
import { toast } from "react-toastify";

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [show, setShow] = useState(false);
  const [showDlt, setShowDlt] = useState(false);
  const [selectTag, setSelectTag] = useState(null);
  const [tagToDelete, setTagToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");

  const tagList = () => {
    getTagList().then((tags) => {
      if (tags?.data) {
        setTags(tags.data);
      }
    });
  };

  useEffect(() => {
    tagList();
  }, []);

  const handleEdit = (tag) => {
    setSelectTag(tag);
    setShow(true);
  };

  const handleDelete = () => {
    deleteTag(tagToDelete.id)
      .then((data) => {
        if (data.error) {
          toast.error(data.error.message);
        } else {
          toast.success("Tag Deleted!");
        }
        setShowDlt(false);
        setTagToDelete(null);
        tagList();
      })
      .catch(() => {
        setShowDlt(false);
        setTagToDelete(null);
      });
  };

  const handleCreate = () => {
    setShow(true);
  };

  const showDelete = (tag) => {
    setTagToDelete(tag);
    setShowDlt(true);
  };

  const handleCloseDlt = () => {
    setShowDlt(false);
    setTagToDelete(null);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const filteredAndSortedTags = tags
    .filter(
      (tag) =>
        tag.tag_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(tag.id).includes(searchTerm)
    )
    .sort((a, b) => {
      const direction = sortDirection === "asc" ? 1 : -1;
      if (sortField === "id") {
        return direction * (a.id - b.id);
      }
      return direction * a[sortField].localeCompare(b[sortField]);
    });

  const headerAction = () => {
    return <Button onClick={handleCreate}>Create Tag</Button>;
  };

  const handleClose = () => {
    setSelectTag(null);
    setShow(false);
  };

  return (
    <MainLayout title="Tags" actionHeader={headerAction}>
      <div className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search tags..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("id")} style={{ cursor: "pointer" }}>
              ID {sortField === "id" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th
              onClick={() => handleSort("tag_name")}
              style={{ cursor: "pointer" }}
            >
              Tag Name{" "}
              {sortField === "tag_name" &&
                (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedTags.map((tag) => (
            <tr key={tag.id}>
              <td>{tag.id}</td>
              <td>{tag.tag_name}</td>
              <td>{actionFormatter(tag, showDelete, handleEdit)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <CreateTag
        show={show}
        handleClose={handleClose}
        reload={tagList}
        obj={selectTag}
      />
      <DeleteConfirmation
        show={showDlt}
        handleClose={handleCloseDlt}
        handleDelete={handleDelete}
        title="Are you sure you want to delete this Tag?"
      />
    </MainLayout>
  );
};

export default Tags;
