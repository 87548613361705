import React, { useEffect, useState } from "react";
import { Container, Row, Button, Form, Modal, Table } from "react-bootstrap";
import {
  createChapter,
  updatechapter,
  getStories,
  getChapterList,
} from "../../utils/endpoints";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";

const CreateChapter = ({
  show,
  handleClose,
  obj = null,
  reload = null,
  storyId,
}) => {
  const [formData, setFormData] = useState({
    chapter_name: "",
    position: "",
    content: "",
    story_id: "",
  });
  const [stories, setStories] = useState([]);
  const [selectedStoryChapters, setSelectedStoryChapters] = useState([]);
  const [showPositionsModal, setShowPositionsModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showStoriesModal, setShowStoriesModal] = useState(false);
  const [storySearchTerm, setStorySearchTerm] = useState("");

  // Form reset when modal opens
  useEffect(() => {
    if (show) {
      if (obj) {
        setFormData({
          chapter_name: obj.chapter_name || "",
          position: obj.position || "",
          content: obj.content || "",
          story_id: obj.story_id || "",
        });
      } else {
        setFormData({
          chapter_name: "",
          position: "",
          content: "",
          story_id: storyId || "",
        });
      }
      loadStories();
    }
  }, [show, obj, storyId]);

  useEffect(() => {
    if (formData.story_id) {
      loadChapters(formData.story_id);
    }
  }, [formData.story_id]);

  const loadStories = async () => {
    try {
      const { data, error } = await getStories();
      if (error) throw error;
      if (data) setStories(data);
    } catch (error) {
      toast.error("Failed to load stories");
    }
  };

  const loadChapters = async (selectedStoryId) => {
    if (!selectedStoryId) return;
    try {
      const { data, error } = await getChapterList(selectedStoryId);
      if (error) throw error;
      if (data) {
        const sortedChapters = data.sort((a, b) => a.position - b.position);
        setSelectedStoryChapters(sortedChapters);
      }
    } catch (error) {
      toast.error("Failed to load chapters");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: null }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.chapter_name)
      newErrors.chapter_name = "Chapter name is required";
    if (!formData.content) newErrors.content = "Content is required";
    if (!formData.position) newErrors.position = "Position is required";
    if (!formData.story_id) newErrors.story_id = "Story is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      console.log("Original content:", formData.content);

      // Content'i olduğu gibi gönder, kullanıcının yaptığı değişiklikler korunsun
      const dataToSubmit = {
        ...formData,
        content: formData.content, // Hiçbir format işlemi yapmadan direk gönder
      };

      console.log("Data to submit:", dataToSubmit);

      const response = obj
        ? await updatechapter(obj.id, dataToSubmit)
        : await createChapter(dataToSubmit);

      console.log("API Response:", response);

      if (response.error) {
        console.error("Save error:", response.error);
        toast.error(response.error);
        return;
      }

      toast.success(obj ? "Chapter updated!" : "Chapter created!");
      if (reload) reload();
      handleClose();
    } catch (error) {
      console.error("Save error:", error);
      toast.error(error.message || "Failed to save chapter");
    } finally {
      setLoading(false);
    }
  };

  const getNextPosition = () => {
    if (!selectedStoryChapters.length) return 1;
    return Math.max(...selectedStoryChapters.map((ch) => ch.position)) + 1;
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{obj ? "Update Chapter" : "Create Chapter"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>
              Story <span className="text-danger">*</span>
            </Form.Label>
            <div className="position-relative">
              <div
                className={`form-control d-flex justify-content-between align-items-center ${
                  !!errors.story_id ? "is-invalid" : ""
                }`}
                onClick={() => !obj && setShowStoriesModal(true)}
                style={{
                  cursor: obj ? "not-allowed" : "pointer",
                  backgroundColor: obj ? "#e9ecef" : "white",
                }}
              >
                <span>
                  {stories.find((s) => s.id === formData.story_id)?.title ||
                    "Select Story"}
                </span>
                {!obj && <i className="fas fa-chevron-down" />}
              </div>
              {errors.story_id && (
                <Form.Text className="text-danger">{errors.story_id}</Form.Text>
              )}
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              Position <span className="text-danger">*</span>
              {formData.story_id && !obj && (
                <span
                  className="text-primary ms-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPositionsModal(true)}
                >
                  (See current positions)
                </span>
              )}
            </Form.Label>
            <Form.Control
              type="number"
              name="position"
              value={formData.position}
              onChange={handleChange}
              isInvalid={!!errors.position}
              disabled={obj}
              style={{ width: "100px" }}
            />
            {errors.position && (
              <Form.Text className="text-danger">{errors.position}</Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              Chapter Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              name="chapter_name"
              value={formData.chapter_name}
              onChange={handleChange}
              isInvalid={!!errors.chapter_name}
            />
            {errors.chapter_name && (
              <Form.Text className="text-danger">
                {errors.chapter_name}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              Content <span className="text-danger">*</span>
            </Form.Label>
            <div className="mb-2">
              <small className="text-muted">
                Enter content in HTML format. Example:{" "}
                <code>&lt;em&gt;text&lt;/em&gt;</code> for italic,{" "}
                <code>&lt;strong&gt;text&lt;/strong&gt;</code> for bold.
              </small>
            </div>
            <Form.Control
              as="textarea"
              rows={10}
              name="content"
              value={formData.content}
              onChange={handleChange}
              isInvalid={!!errors.content}
              style={{ fontFamily: "inherit" }}
              placeholder="<em>Example text</em> with HTML tags..."
            />
            {errors.content && (
              <Form.Text className="text-danger">{errors.content}</Form.Text>
            )}
            <div className="mt-2">
              <small className="text-muted">
                Common HTML tags: <br />- <code>&lt;em&gt;text&lt;/em&gt;</code>{" "}
                for italic text
                <br />- <code>&lt;strong&gt;text&lt;/strong&gt;</code> for bold
                text
                <br />- <code>&lt;p&gt;text&lt;/p&gt;</code> for paragraphs
                <br />- <code>&lt;br /&gt;</code> for line breaks
              </small>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>

      <Modal
        show={showPositionsModal}
        onHide={() => setShowPositionsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Current Positions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <strong>Next available position: {getNextPosition()}</strong>
          </div>
          {selectedStoryChapters.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Chapter Name</th>
                </tr>
              </thead>
              <tbody>
                {selectedStoryChapters.map((chapter) => (
                  <tr key={chapter.id}>
                    <td>{chapter.position}</td>
                    <td>{chapter.chapter_name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No chapters found for this story.</p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showStoriesModal}
        onHide={() => setShowStoriesModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Story</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Search stories..."
            value={storySearchTerm}
            onChange={(e) => setStorySearchTerm(e.target.value)}
            className="mb-3"
          />
          <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {stories
                  .filter((story) =>
                    story.title
                      .toLowerCase()
                      .includes((storySearchTerm || "").toLowerCase())
                  )
                  .map((story) => (
                    <tr key={story.id}>
                      <td>{story.title}</td>
                      <td>{story.description}</td>
                      <td>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => {
                            setFormData((prev) => ({
                              ...prev,
                              story_id: story.id,
                              position: getNextPosition(),
                            }));
                            setShowStoriesModal(false);
                          }}
                        >
                          Select
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </Modal>
  );
};

export default CreateChapter;
