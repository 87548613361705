import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout";
import {
  Button,
  Form,
  Table,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  deleteScrollableStory,
  getStories,
  insertScrollableStory,
  scrollableStory,
} from "../../utils/endpoints";
import { toast } from "react-toastify";

const ScrollStory = () => {
  const [stories, setStories] = useState([]);
  const [scrollStories, setScrollStories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStory, setSelectedStory] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [storyToAdd, setStoryToAdd] = useState(null);
  const [showRemoveConfirmModal, setShowRemoveConfirmModal] = useState(false);
  const [storyToRemove, setStoryToRemove] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [modalSortOrder, setModalSortOrder] = useState("asc");

  useEffect(() => {
    getStoriesList();
    getScrollableStories();
  }, []);

  const getStoriesList = async () => {
    const { data } = await getStories();
    if (data) setStories(data);
  };

  const getScrollableStories = async () => {
    const { data } = await scrollableStory();
    if (data) setScrollStories(data);
  };

  const handleAdd = async (story) => {
    try {
      const { error } = await insertScrollableStory({
        story_id: story.id,
      });
      if (error) throw error;
      toast.success("Story added to scrollable list!");
      getScrollableStories();
    } catch (error) {
      toast.error("Failed to add story!");
    }
  };

  const handleRemove = async (id) => {
    try {
      const { error } = await deleteScrollableStory(id);
      if (error) throw error;
      toast.success("Story removed from scrollable list!");
      getScrollableStories();
      setShowRemoveConfirmModal(false);
      setStoryToRemove(null);
    } catch (error) {
      toast.error("Failed to remove story!");
    }
  };

  // Modal içindeki sıralama fonksiyonu
  const getSortedStories = () => {
    const filtered = stories.filter(
      (story) =>
        story.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        story.description?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return filtered
      .filter((story) => !scrollStories.some((ss) => ss.story_id === story.id))
      .sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        return modalSortOrder === "asc"
          ? titleA.localeCompare(titleB)
          : titleB.localeCompare(titleA);
      });
  };

  // Ana tablodaki sıralama fonksiyonu
  const handleMainSort = () => {
    const sorted = [...scrollStories].sort((a, b) => {
      const storyA =
        stories.find((s) => s.id === a.story_id)?.title.toLowerCase() || "";
      const storyB =
        stories.find((s) => s.id === b.story_id)?.title.toLowerCase() || "";
      return sortOrder === "asc"
        ? storyA.localeCompare(storyB)
        : storyB.localeCompare(storyA);
    });
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setScrollStories(sorted);
  };

  return (
    <MainLayout title="Scroll Story">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Add Story to Scroll
        </Button>
        <div className="d-flex gap-4">
          <div
            style={{
              background: "#f8f9fa",
              padding: "10px 15px",
              borderRadius: "8px",
              border: "1px solid #dee2e6",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <span style={{ fontWeight: "bold", color: "#495057" }}>
              Total Scroll Stories:
            </span>
            <span
              style={{
                marginLeft: "8px",
                fontSize: "1.1em",
                color: "#0d6efd",
                fontWeight: "500",
              }}
            >
              {scrollStories.length}
            </span>
          </div>
        </div>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ cursor: "pointer" }} onClick={handleMainSort}>
              Story Title {sortOrder === "asc" ? "↑" : "↓"}
            </th>
            <th>Description</th>
            <th>Rating</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {scrollStories.map((story) => {
            const storyDetails = stories.find((s) => s.id === story.story_id);
            return (
              <tr key={story.id}>
                <td>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip style={{ maxWidth: "none" }}>
                        <img
                          src={storyDetails?.url}
                          alt={storyDetails?.title}
                          style={{
                            width: "100px",
                            height: "137px",
                            objectFit: "cover",
                            borderRadius: "4px",
                          }}
                        />
                      </Tooltip>
                    }
                  >
                    <span style={{ cursor: "pointer" }}>
                      {storyDetails?.title}
                    </span>
                  </OverlayTrigger>
                </td>
                <td>{storyDetails?.description}</td>
                <td>{storyDetails?.custom_rating || 0}</td>
                <td>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      setStoryToRemove(story);
                      setShowRemoveConfirmModal(true);
                    }}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Story to Scroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <Form.Control
              type="text"
              placeholder="Search stories by title or description..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "60%" }}
            />
            <div className="d-flex gap-3">
              <div
                style={{
                  background: "#f8f9fa",
                  padding: "10px 15px",
                  borderRadius: "8px",
                  whiteSpace: "nowrap",
                  border: "1px solid #dee2e6",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#495057" }}>
                  Total Stories:
                </span>
                <span
                  style={{
                    marginLeft: "8px",
                    fontSize: "1.1em",
                    color: "#0d6efd",
                    fontWeight: "500",
                  }}
                >
                  {stories.length}
                </span>
              </div>
            </div>
          </div>
          <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            <Table striped bordered hover>
              <thead
                style={{ position: "sticky", top: 0, background: "white" }}
              >
                <tr>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setModalSortOrder(
                        modalSortOrder === "asc" ? "desc" : "asc"
                      )
                    }
                  >
                    Title {modalSortOrder === "asc" ? "↑" : "↓"}
                  </th>
                  <th>Description</th>
                  <th>Rating</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getSortedStories().map((story) => (
                  <tr key={story.id}>
                    <td>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip style={{ maxWidth: "none" }}>
                            <img
                              src={story.url}
                              alt={story.title}
                              style={{
                                width: "100px",
                                height: "137px",
                                objectFit: "cover",
                                borderRadius: "4px",
                              }}
                            />
                          </Tooltip>
                        }
                      >
                        <span style={{ cursor: "pointer" }}>{story.title}</span>
                      </OverlayTrigger>
                    </td>
                    <td>{story.description}</td>
                    <td>{story.custom_rating || 0}</td>
                    <td>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => {
                          setStoryToAdd(story);
                          setShowConfirmModal(true);
                        }}
                      >
                        Add
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Addition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to add this story to scroll list?</p>
          <p className="mb-0">
            <strong>Story:</strong> {storyToAdd?.title}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              handleAdd(storyToAdd);
              setShowConfirmModal(false);
              setShowModal(false);
              setStoryToAdd(null);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showRemoveConfirmModal}
        onHide={() => setShowRemoveConfirmModal(false)}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Remove</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this story from scroll list?</p>
          <p className="mb-0">
            <strong>Story:</strong>{" "}
            {stories.find((s) => s.id === storyToRemove?.story_id)?.title}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowRemoveConfirmModal(false);
              setStoryToRemove(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleRemove(storyToRemove?.story_id)}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};

export default ScrollStory;
