import React from "react";
import { Button, Dropdown } from "react-bootstrap";

const actionFormatter = (id, showDelete, handleEdit, navigate) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Action
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleEdit(id)}>Edit</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate(`/chapters?storyId=${id}`)}>
          Chapters
        </Dropdown.Item>
        <Dropdown.Item onClick={() => showDelete(id)}>Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default actionFormatter;
