import { supabase } from "./supabaseClient";
import { toast } from "react-toastify";

// STORY ENDPOINTS
export const getStories = async () => {
  return await supabase.from("story").select("*");
};

export const getStory = async (id) => {
  return await supabase.from("story").select("*").eq("id", id);
};

export const delectStory = async (id) => {
  return await supabase.from("story").delete().eq("id", id);
};

export const createStory = async (data) => {
  const storyData = {
    title: data.title,
    description: data.description,
    story_status: data.story_status,
    sub_title: data.sub_title,
    url: data.url,
    rating_count: parseInt(data.rating_count || 0),
    custom_rating: parseFloat(data.custom_rating || 0),
    premium: data.premium || false,
    preview_chapter_count: data.premium
      ? parseInt(data.preview_chapter_count) || 0
      : 0,
  };

  console.log("Creating story with data:", storyData); // Debug için
  return await supabase.from("story").insert([storyData]).select();
};

export const updateStory = async (data, id) => {
  console.log("Updating story with data:", data); // Debug için

  try {
    // Ana story verilerini güncelle
    const storyData = {
      title: data.title,
      description: data.description,
      story_status: data.story_status,
      sub_title: data.sub_title,
      url: data.url,
      rating_count: data.rating_count,
      custom_rating: data.custom_rating,
      premium: data.premium || false,
      preview_chapter_count: data.premium ? data.preview_chapter_count : null,
    };

    const { data: storyResult, error: storyError } = await supabase
      .from("story")
      .update(storyData)
      .eq("id", id)
      .select();

    if (storyError) throw storyError;

    // Önce eski tag ve section verilerini sil
    await supabase.from("story_tag").delete().eq("story_id", id);
    await supabase.from("story_section").delete().eq("story_id", id);

    // Yeni tag'leri ekle
    if (data.tag) {
      // Main tag
      await createStoryTag([
        {
          story_id: id,
          tag_id: data.tag,
          tag_types: "main_tag",
        },
      ]);

      // Sub tags
      if (data.sub_tag && data.sub_tag.length > 0) {
        const subTagData = data.sub_tag.map((tagId) => ({
          story_id: id,
          tag_id: tagId,
          tag_types: "sub_tag",
        }));
        await createStoryTag(subTagData);
      }
    }

    // Yeni section'ları ekle
    if (data.section && data.section.length > 0) {
      const sectionData = data.section.map((sectionId) => ({
        story_id: id,
        section_id: sectionId,
      }));
      await createStorySection(sectionData);
    }

    return storyResult;
  } catch (error) {
    console.error("Update error:", error); // Debug için
    throw error;
  }
};

// TAGS ENDPOINTS
export const getTagList = async () => {
  return await supabase.from("tags").select("*");
};

export const deleteTag = async (id) => {
  return await supabase.from("tags").delete().eq("id", id);
};

export const createTag = async (data) => {
  return await supabase.from("tags").insert([data]).select();
};

export const updateTag = async (data, id) => {
  return await supabase.from("tags").update(data).eq("id", id).select();
};

// SECTION ENDPOINTS
export const getSectionList = async () => {
  return await supabase.from("sections").select("*");
};

export const createSection = async (data) => {
  return await supabase.from("sections").insert([data]).select();
};

export const updateSection = async (data, id) => {
  return await supabase.from("sections").update(data).eq("id", id).select();
};

export const deleteSection = async (id) => {
  return await supabase.from("sections").delete().eq("id", id);
};

export const createStoryTag = async (data) => {
  return await supabase.from("story_tag").insert(data).select();
};
export const createStorySection = async (data) => {
  return await supabase.from("story_section").insert(data).select();
};

// CHAPTER ENDPOINTS

export const getChapterList = async (id) => {
  return await supabase.from("chapters").select("*").eq("story_id", id);
};

export const getChaptersList = async (id) => {
  return await supabase.from("chapters").select("*");
};

export const deleteChapter = async (id) => {
  return await supabase.from("chapters").delete().eq("id", id);
};

export const createChapter = async (data) => {
  try {
    const { data: result, error } = await supabase
      .from("chapters")
      .insert([data])
      .select();

    if (error) {
      console.log("Supabase error:", error); // Debug için

      // Position duplicate hatasını kontrol et
      if (
        error.code === "23505" ||
        error.message?.includes("unique constraint")
      ) {
        return {
          error: `Position ${data.position} is already taken in this story. Please choose a different position.`,
        };
      }
      return { error: error.message };
    }

    return { data: result[0] };
  } catch (error) {
    console.error("Create error:", error);
    return { error: error.message };
  }
};

export const updatechapter = async (id, data) => {
  try {
    console.log("Update data before:", data);

    // Supabase'e göndermeden önce content'i düzenle
    const { data: updatedData, error } = await supabase
      .from("chapters")
      .update({
        chapter_name: data.chapter_name,
        content: data.content.replace(/\n{3,}/g, "\n\n"), // 3 veya daha fazla boş satırı 2 boş satıra indir
        position: data.position,
        story_id: data.story_id,
      })
      .eq("id", id)
      .select("*");

    console.log("Update response:", updatedData);

    if (error) {
      console.error("Update error:", error);
      return { error };
    }

    return { data: updatedData[0] };
  } catch (error) {
    console.error("Update error:", error);
    return { error };
  }
};

export const getStoryTag = async (id) => {
  return await supabase.from("story_tag").select("*").eq("story_id", id);
};

export const getStorySection = async (id) => {
  return await supabase
    .from("story_section")
    .select("section_id")
    .eq("story_id", id);
};

export const getTagIds = async (id) => {
  return await supabase.from("story_tag").select("*").eq("story_id", id);
};

export const getSectionIds = async (id) => {
  return await supabase
    .from("story_section")
    .select("section_id")
    .eq("story_id", id);
};

export const updateStoryTag = async (data, id) => {
  await supabase.from("story_tag").delete().eq("story_id", id);
  return await supabase.from("story_tag").insert(data).select();
};

export const updateStorySection = async (data, id) => {
  const deletedData = await supabase
    .from("story_section")
    .delete()
    .eq("story_id", id)
    .select();
  if (deletedData.data) {
    return await supabase.from("story_section").insert(data).select();
  }
};

export const scrollableStory = async () => {
  return await supabase.from("scrollable_stories").select("*");
};

export const insertScrollableStory = async (data) => {
  return await supabase.from("scrollable_stories").insert([data]).select();
};

export const deleteScrollableStory = async (id) => {
  return await supabase.from("scrollable_stories").delete().eq("story_id", id);
};

export const getLoginDetails = async (email, password) => {
  return await supabase
    .from("auth_users")
    .select("*")
    .eq("email", email)
    .eq("password", password)
    .single();
};

export const createUser = async (data) => {
  return await supabase.from("auth_users").insert([data]).select();
};

export const updatePassword = async (oldPassword, newPassword, id) => {
  const { data: user, error } = await supabase
    .from("auth_users")
    .select("password")
    .eq("id", id)
    .single();

  if (error || user.password !== oldPassword) {
    return { error: true, message: "Old password is incorrect" };
  }

  return await supabase
    .from("auth_users")
    .update({ password: newPassword })
    .eq("id", id)
    .select();
};

export const sendTelegramNotification = async (message) => {
  const BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
  const CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID;

  if (!BOT_TOKEN || !CHAT_ID) {
    console.error("Telegram credentials are missing:", { BOT_TOKEN, CHAT_ID });
    toast.error("Telegram configuration is missing");
    return;
  }

  try {
    // Özel karakterleri escape edelim
    const escapedMessage = message.replace(/[_*[\]()~`>#+=|{}.!-]/g, "\\$&");

    // Direkt olarak API'yi çağıralım
    const response = await fetch(
      `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: CHAT_ID,
          text: escapedMessage,
          parse_mode: "MarkdownV2",
        }),
      }
    );

    const data = await response.json();
    console.log("Telegram API response:", data);

    if (!response.ok) {
      throw new Error(`Telegram notification failed: ${data.description}`);
    }

    return data;
  } catch (error) {
    console.error("Telegram notification failed:", error);
    toast.error("Failed to send notification");
    throw error;
  }
};
