import * as Yup from "yup";

export const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  url: Yup.mixed().required("Image is required"),
  tag: Yup.number().required("Tag is required"),
  tag_type: Yup.string().required("Tag Type is required"),
  section: Yup.array()
    .min(1, "At least one section is required")
    .required("Section is required"),
  custom_rating: Yup.number()
    .required("Rating is required")
    .min(0, "Rating cannot be less than 0")
    .max(5, "Rating cannot be greater than 5")
    .test("decimal", "Only one decimal place is allowed", (value) => {
      if (!value) return true;
      return /^\d+(\.\d{0,1})?$/.test(value.toString());
    }),
  rating_count: Yup.number()
    .required("Rating Count is required")
    .min(0, "Rating Count cannot be less than 0")
    .integer("Rating Count must be a whole number"),
});
