import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout";
import { getSectionList, deleteSection } from "../../utils/endpoints";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import CreateSection from "./createSection";
import DeleteConfirmation from "../modalView/deleteConfirmation";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { supabase } from "../../utils/supabaseClient";
import { Modal } from "react-bootstrap";

const Contents = () => {
  const [sections, setSections] = useState([]);
  const [show, setShow] = useState(false);
  const [showDlt, setShowDlt] = useState(false);
  const [selectSection, setSelectSection] = useState(null);
  const [sectionToDelete, setSectionToDelete] = useState(null);

  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedSectionContent, setSelectedSectionContent] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editableContent, setEditableContent] = useState([]);
  const [selectedSectionName, setSelectedSectionName] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleEdit = async (obj) => {
    try {
      console.log("Selected section:", obj);

      const { data, error } = await supabase
        .from("story_section")
        .select(
          `
          id,
          story_id,
          section_id,
          display_order,
          story:story_id (
            title,
            url,
            description,
            custom_rating,
            rating_count,
            story_tag!story_id (
              tag_id,
              tag_types,
              tag:tag_id (
                tag_name
              )
            )
          )
        `
        )
        .eq("section_id", obj.id)
        .order("display_order");

      if (error) throw error;

      console.log("Fetched content:", data);

      const contentWithOrder = data.map((item) => ({
        ...item,
        display_order: item.display_order || 999,
        section_id: obj.id,
        story: {
          ...item.story,
          main_tag: item.story.story_tag.find(
            (st) => st.tag_types === "main_tag"
          )?.tag,
          sub_tags: item.story.story_tag
            .filter((st) => st.tag_types === "sub_tag")
            .map((st) => st.tag),
        },
      }));

      setSelectedSectionContent(contentWithOrder);
      setEditableContent(contentWithOrder);
      setSelectedSectionName(obj.section_name);
      setShowContentModal(true);
    } catch (error) {
      console.error("Error fetching section content:", error);
      toast.error("Failed to load section content");
    }
  };

  const handleDelete = () => {
    deleteSection(sectionToDelete.id)
      .then((data) => {
        if (data.error) {
          toast.error(data.error.message);
        } else {
          toast.success("Section Deleted!");
        }
        setShowDlt(false);
        setSectionToDelete(null);
        sectionList();
      })
      .catch(() => {
        setShowDlt(false);
        setSectionToDelete(null);
      });
  };

  const handleCreate = () => {
    setShow(true);
  };

  const showDelete = (section) => {
    setSectionToDelete(section);
    setShowDlt(true);
  };

  const handleCloseDlt = () => {
    setShowDlt(false);
    setSectionToDelete(null);
  };

  const handleOrderChange = (index, newOrder) => {
    const newContent = [...editableContent];
    newContent[index].display_order = parseInt(newOrder) || 999;
    setEditableContent(newContent);
  };

  const handleSaveOrder = async () => {
    try {
      console.log("Selected section content:", selectedSectionContent);
      console.log("Editable content:", editableContent);

      const sectionId = selectedSectionContent[0]?.section_id;
      if (!sectionId) {
        throw new Error("Section ID not found");
      }

      const { data: currentData, error: fetchError } = await supabase
        .from("story_section")
        .select("id, display_order")
        .eq("section_id", sectionId);

      if (fetchError) {
        throw fetchError;
      }

      const changedItems = editableContent.filter((newItem) => {
        const currentItem = currentData.find((item) => item.id === newItem.id);
        return (
          currentItem && currentItem.display_order !== newItem.display_order
        );
      });

      console.log("Changed items:", changedItems);

      const updatePromises = changedItems.map((content) => {
        console.log("Updating item:", content);
        return supabase
          .from("story_section")
          .update({
            display_order: content.display_order,
          })
          .eq("id", content.id);
      });

      if (updatePromises.length > 0) {
        const results = await Promise.all(updatePromises);
        const errors = results.filter((result) => result.error);

        if (errors.length > 0) {
          console.error("Update errors:", errors);
          throw new Error("Some updates failed");
        }

        toast.success("Order updated successfully");
        console.log("Updates completed:", results);
      } else {
        toast.info("No changes to save");
      }

      setEditMode(false);
      setSelectedSectionContent(editableContent);
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error(`Failed to update order: ${error.message}`);
    }
  };

  const handleRemoveContent = async (contentId) => {
    try {
      const { error } = await supabase
        .from("story_section")
        .delete()
        .eq("id", contentId);

      if (error) throw error;

      const newContent = editableContent.filter(
        (item) => item.id !== contentId
      );
      setEditableContent(newContent);
      setSelectedSectionContent(newContent);
      toast.success("Content removed successfully");
    } catch (error) {
      console.error("Error removing content:", error);
      toast.error("Failed to remove content");
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const getSortedSections = () => {
    return [...sections].sort((a, b) => {
      const direction = sortDirection === "asc" ? 1 : -1;
      if (sortField === "id") {
        return direction * (a.id - b.id);
      }
      return direction * a[sortField].localeCompare(b[sortField]);
    });
  };

  const sectionList = () => {
    getSectionList().then((sections) => {
      if (sections?.data) {
        setSections(sections.data);
      }
    });
  };

  useEffect(() => {
    sectionList();
  }, []);

  const headerAction = () => {
    return <Button onClick={handleCreate}>Create Section</Button>;
  };

  const handleClose = () => {
    setSelectSection(null);
    setShow(false);
  };

  const actionFormatter = (row) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          Action
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <OverlayTrigger
            placement="left"
            overlay={
              row.is_random ? (
                <Tooltip>
                  Content order cannot be modified when random order is enabled
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            <div>
              <Dropdown.Item
                onClick={() => !row.is_random && handleEdit(row)}
                style={{
                  opacity: row.is_random ? 0.5 : 1,
                  cursor: row.is_random ? "not-allowed" : "pointer",
                }}
              >
                View Content Order
              </Dropdown.Item>
            </div>
          </OverlayTrigger>
          <Dropdown.Item onClick={() => setSelectSection(row) || setShow(true)}>
            Edit Section
          </Dropdown.Item>
          <Dropdown.Item onClick={() => showDelete(row)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <MainLayout title="Sections" actionHeader={headerAction}>
      <div className="">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th
                onClick={() => handleSort("id")}
                style={{ cursor: "pointer" }}
              >
                ID {sortField === "id" && (sortDirection === "asc" ? "↑" : "↓")}
              </th>
              <th
                onClick={() => handleSort("section_name")}
                style={{ cursor: "pointer" }}
              >
                Name{" "}
                {sortField === "section_name" &&
                  (sortDirection === "asc" ? "↑" : "↓")}
              </th>
              <th>View Type</th>
              <th style={{ textAlign: "center" }}>Random</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getSortedSections().map((section) => (
              <tr key={section.id}>
                <td>{section.id}</td>
                <td>{section.section_name}</td>
                <td>{section.view_types}</td>
                <td style={{ textAlign: "center" }}>
                  <Form.Check
                    type="switch"
                    checked={section.is_random}
                    onChange={async (e) => {
                      try {
                        const { error } = await supabase
                          .from("sections")
                          .update({ is_random: e.target.checked })
                          .eq("id", section.id);

                        if (error) throw error;

                        toast.success(
                          `Random order ${
                            e.target.checked ? "enabled" : "disabled"
                          }`
                        );
                        sectionList(); // Listeyi yenile
                      } catch (error) {
                        console.error("Error updating random status:", error);
                        toast.error("Failed to update random status");
                      }
                    }}
                    style={{ transform: "scale(1.3)" }}
                  />
                </td>
                <td>{actionFormatter(section)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <CreateSection
        show={show}
        handleClose={handleClose}
        reload={sectionList}
        obj={selectSection}
      />
      <DeleteConfirmation
        show={showDlt}
        handleClose={handleCloseDlt}
        handleDelete={handleDelete}
        title="Are you sure you want to delete this Section?"
      />
      <Modal
        show={showContentModal}
        onHide={() => setShowContentModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Content Order for "{selectedSectionName}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-info">
            <strong>Order Rules:</strong>
            <ul className="mb-0">
              <li>The order must start from 1 and be consecutive</li>
              <li>The value 999 represents random order</li>
              <li>A maximum value of 999 can be entered</li>
            </ul>
          </div>
          <div className="mb-3">
            <Button
              variant={editMode ? "success" : "primary"}
              onClick={() => {
                if (editMode) {
                  const nonRandomOrders = editableContent
                    .filter((c) => c.display_order !== 999)
                    .map((c) => c.display_order)
                    .sort((a, b) => a - b);

                  const hasGaps = nonRandomOrders.some(
                    (order, index) => order !== index + 1
                  );

                  if (hasGaps) {
                    toast.error(
                      "The order must start from 1 and be consecutive (excluding random orders)!"
                    );
                    return;
                  }

                  handleSaveOrder();
                } else {
                  setEditMode(true);
                }
              }}
            >
              {editMode ? "Save Changes" : "Edit Order"}
            </Button>
            {editMode && (
              <Button
                variant="secondary"
                className="ms-2"
                onClick={() => {
                  setEditMode(false);
                  setEditableContent(selectedSectionContent);
                }}
              >
                Cancel
              </Button>
            )}
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const sorted = [
                      ...(editMode ? editableContent : selectedSectionContent),
                    ].sort((a, b) => {
                      if (sortOrder === "asc") {
                        return b.display_order - a.display_order;
                      }
                      return a.display_order - b.display_order;
                    });

                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    if (editMode) {
                      setEditableContent(sorted);
                    } else {
                      setSelectedSectionContent(sorted);
                    }
                  }}
                >
                  Display Order {sortOrder === "asc" ? "↑" : "↓"}
                </th>
                <th>Story Title</th>
                <th>Rating</th>
                <th>Rating Count</th>
                {editMode && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {(editMode ? editableContent : selectedSectionContent).map(
                (content, index) => (
                  <tr key={index}>
                    <td>
                      {editMode ? (
                        <Form.Control
                          type="number"
                          value={content.display_order}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 999) {
                              toast.error("A maximum of 999 can be entered!");
                              return;
                            }
                            handleOrderChange(index, value);
                          }}
                          min="1"
                          max="999"
                          style={{ width: "80px" }}
                        />
                      ) : content.display_order === 999 ? (
                        "Random"
                      ) : (
                        content.display_order
                      )}
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip
                            id={`story-tooltip-${index}`}
                            style={{
                              maxWidth: "none",
                              background: "rgba(0, 0, 0, 0.9)",
                              color: "white",
                              padding: "15px",
                              borderRadius: "8px",
                              boxShadow: "0 4px 20px rgba(0,0,0,0.3)",
                              minWidth: "600px",
                              width: "max-content",
                              maxWidth: "800px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                                width: "100%",
                              }}
                            >
                              <div>
                                <img
                                  src={content.story?.url}
                                  alt={content.story?.title}
                                  style={{
                                    width: "120px",
                                    height: "165px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
                                    flexShrink: 0,
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  minWidth: "400px",
                                  maxWidth: "600px",
                                }}
                              >
                                <h5
                                  style={{
                                    marginBottom: "15px",
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                  }}
                                >
                                  {content.story?.title}
                                </h5>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "30px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <p style={{ margin: 0, color: "white" }}>
                                    <strong>Main Tag:</strong>{" "}
                                    <span style={{ fontSize: "1.1rem" }}>
                                      {content.story?.main_tag?.tag_name || "-"}
                                    </span>
                                  </p>
                                  <p style={{ margin: 0, color: "white" }}>
                                    <strong>Sub Tags:</strong>{" "}
                                    <span style={{ fontSize: "1.1rem" }}>
                                      {content.story?.sub_tags
                                        ?.map((tag) => tag.tag_name)
                                        .join(", ") || "-"}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      color: "#ccc",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Description:
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      color: "white",
                                      lineHeight: "1.5",
                                      maxHeight: "100px",
                                      overflow: "auto",
                                      paddingRight: "10px",
                                      textAlign: "justify",
                                      width: "100%",
                                      WebkitScrollbarWidth: "thin",
                                      scrollbarWidth: "thin",
                                      scrollbarColor:
                                        "rgba(255,255,255,0.3) transparent",
                                    }}
                                  >
                                    {content.story?.description ||
                                      "No description"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        }
                      >
                        <span style={{ cursor: "pointer" }}>
                          {content.story?.title || "Unknown Story"}
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td>{content.story?.custom_rating || "0"}</td>
                    <td>{content.story?.rating_count || "0"}</td>
                    {editMode && (
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleRemoveContent(content.id)}
                        >
                          Remove
                        </Button>
                      </td>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </Table>
          {selectedSectionContent.length === 0 && (
            <div className="text-center py-3 text-muted">
              No content in this section
            </div>
          )}
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
};

export default Contents;
