import { PutObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3";
import { region, s3Client } from "./awsConfig";

export const uploadToS3 = async (file) => {
  try {
    const uniqueFileName = `${Date.now()}-${file.name.replace(
      /[^a-zA-Z0-9.]/g,
      ""
    )}`;

    // Dosyayı direkt yükle
    await s3Client.send(
      new PutObjectCommand({
        Bucket: "sentenceapp",
        Key: uniqueFileName,
        Body: file,
        ContentType: file.type,
        CacheControl: "public, max-age=31536000",
      })
    );

    // CloudFront URL'ini oluştur
    const cdnUrl = `https://${process.env.REACT_APP_CLOUDFRONT_DOMAIN}/${uniqueFileName}`;

    return {
      key: uniqueFileName,
      url: cdnUrl,
      originalSize: file.size / 1024,
    };
  } catch (error) {
    console.error("Upload error:", error);
    throw error;
  }
};

export const deleteFromS3 = async (fileName) => {
  if (!fileName) return null;

  const deleteParams = {
    Bucket: "sentenceapp",
    Key: fileName,
  };

  try {
    await s3Client.send(new DeleteObjectCommand(deleteParams));
    return true;
  } catch (err) {
    console.error("Delete error:", err);
    return false;
  }
};
