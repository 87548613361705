import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button, Form as BootstrapForm, Container, Row, Col } from 'react-bootstrap';
import MainLayout from '../MainLayout';
import { updatePassword } from '../../utils/endpoints'; // Replace with the function to update password

const ChangePassword = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required('Old password is required'),
    newPassword: Yup.string()
      .min(6, 'New password must be at least 6 characters')
      .required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const onSubmit = (values) => {
    const { oldPassword, newPassword } = values;
    setIsSubmitting(true);

    let userData = localStorage.getItem("authToken");
    userData = JSON.parse(userData);

    updatePassword(oldPassword, newPassword, userData.id)
      .then((data) => {
        if (data.data) {
          toast.success('Password updated successfully');
          navigate('/');
        } else {
          toast.error('Password update failed');
        }
        setIsSubmitting(false);
      })
      .catch(() => {
        toast.error('An error occurred');
        setIsSubmitting(false);
      });
  };

  return (
    <MainLayout title="Change Password">
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="w-100">
          <Col md={{ span: 4, offset: 4 }}>
            <h2 className="text-center mb-4">Change Password</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors }) => (
                <Form>
                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Old Password</BootstrapForm.Label>
                    <Field
                      name="oldPassword"
                      type="password"
                      as={BootstrapForm.Control}
                      isInvalid={touched.oldPassword && !!errors.oldPassword}
                    />
                    <ErrorMessage name="oldPassword" component={BootstrapForm.Control.Feedback} type="invalid" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>New Password</BootstrapForm.Label>
                    <Field
                      name="newPassword"
                      type="password"
                      as={BootstrapForm.Control}
                      isInvalid={touched.newPassword && !!errors.newPassword}
                    />
                    <ErrorMessage name="newPassword" component={BootstrapForm.Control.Feedback} type="invalid" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Confirm Password</BootstrapForm.Label>
                    <Field
                      name="confirmPassword"
                      type="password"
                      as={BootstrapForm.Control}
                      isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                    />
                    <ErrorMessage name="confirmPassword" component={BootstrapForm.Control.Feedback} type="invalid" />
                  </BootstrapForm.Group>

                  <Button variant="primary" type="submit" disabled={isSubmitting} className="w-100">
                    {isSubmitting ? 'Updating Password...' : 'Change Password'}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ChangePassword;
