import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Stories from "./component/stories";
import Contents from "./component/content";
import Story from "./component/stories/story";
import Chapters from "./component/chapters";
import Tags from "./component/tags";
import ScrollStory from "./component/scrollStory";
import Login from "./component/login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLoginDetails } from "./utils/endpoints";
import CreateAccount from "./component/createUser";
import ChangePassword from "./component/changePassword";

const isAuthenticated = () => {
  checkCreds();
  return !!localStorage.getItem("authToken");
};

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

const checkCreds = () => {
  const useData = localStorage.getItem("authToken");
  const user = JSON.parse(useData);
  if (user) {
    getLoginDetails(user.email, user.password).then((data) => {
      if (data.error) {
        localStorage.removeItem("authToken");
      }
    });
  }
};

const App = () => {
  let userData = localStorage.getItem("authToken");
  userData = JSON.parse(userData);
  return (
    <div className="App wrapper">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Stories />
              </PrivateRoute>
            }
          />
          <Route
            path="/story/:id"
            element={
              <PrivateRoute>
                <Story />
              </PrivateRoute>
            }
          />
          <Route
            path="/contents"
            element={
              <PrivateRoute>
                <Contents />
              </PrivateRoute>
            }
          />
          <Route
            path="/story/create"
            element={
              <PrivateRoute>
                <Story />
              </PrivateRoute>
            }
          />
          <Route
            path="stories/:storyId/chapters"
            element={
              <PrivateRoute>
                <Chapters />
              </PrivateRoute>
            }
          />
          <Route
            path="/tags"
            element={
              <PrivateRoute>
                <Tags />
              </PrivateRoute>
            }
          />
          <Route
            path="/chapters"
            element={
              <PrivateRoute>
                <Chapters />
              </PrivateRoute>
            }
          />
          <Route
            path="/scrollstory"
            element={
              <PrivateRoute>
                <ScrollStory />
              </PrivateRoute>
            }
          />
          <Route
            path="/changepassword"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          {userData?.admin && (
            <Route
              path="/create"
              element={
                <PrivateRoute>
                  <CreateAccount />
                </PrivateRoute>
              }
            />
          )}
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
