import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout";
import { delectStory, getStories } from "../../utils/endpoints";
import { Button, Col, Image, Row, Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import actionFormatter from "./actions";
import DeleteConfirmation from "../modalView/deleteConfirmation";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const Stories = () => {
  const [stories, setStories] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showDlt, setShowDlt] = useState(false);
  const [storyToDelete, setStoryToDelete] = useState(null);
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState("title");
  const [sortDirection, setSortDirection] = useState("asc");
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportFormat, setExportFormat] = useState("json");

  const getStoriesTable = () => {
    getStories().then((data) => {
      if (data.error) {
        toast.error("Failed Loading Stories.");
      } else {
        setLoader(false);
        setStories(data.data);
      }
    });
  };

  useEffect(() => {
    getStoriesTable();
  }, []);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const getSortedAndFilteredStories = () => {
    return [...stories]
      .filter((story) =>
        story.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const direction = sortDirection === "asc" ? 1 : -1;

        if (sortField === "id") {
          return direction * (parseInt(a.id) - parseInt(b.id));
        } else if (sortField === "custom_rating") {
          return direction * ((a.custom_rating || 0) - (b.custom_rating || 0));
        } else if (sortField === "rating_count") {
          return direction * ((a.rating_count || 0) - (b.rating_count || 0));
        } else if (sortField === "title") {
          return direction * a.title.localeCompare(b.title);
        }
        return 0;
      });
  };

  const imageFormatter = (_cell, row) => {
    const src = row.url;
    return (
      <Row>
        <Col className="text-left">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${row.id}`}>{src}</Tooltip>}
          >
            <div
              style={{
                width: "100px",
                height: "137px",
                border: "1px solid #dee2e6",
                borderRadius: "4px",
                overflow: "hidden",
                backgroundColor: "#f8f9fa",
              }}
            >
              <Image
                src={src}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                alt={`${row.title} story cover`}
                onClick={() => {
                  setSelectedImageUrl(src);
                  setShowImageModal(true);
                }}
              />
            </div>
          </OverlayTrigger>
        </Col>
      </Row>
    );
  };

  const handleEdit = (id) => {
    navigate(`/story/${id}`);
  };

  const handleDelete = () => {
    setLoader(true);
    delectStory(storyToDelete)
      .then((dltData) => {
        if (dltData.error) {
          toast.error("Failed to delete the story.");
        } else {
          toast.success("Story deleted successfully");
          getStoriesTable();
        }
        setShowDlt(false);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        toast.error("Failed to delete the story.");
      });
  };

  const handleCreate = () => {
    navigate(`/story/create`);
  };

  const headerAction = () => {
    return <Button onClick={handleCreate}>Create Story</Button>;
  };

  const showDelete = (id) => {
    setStoryToDelete(id);
    setShowDlt(true);
  };

  const handleCloseDlt = () => {
    setShowDlt(false);
    setStoryToDelete(null);
  };

  const handleExport = () => {
    const exportData = stories.map((story) => ({
      ID: story.id,
      Title: story.title,
      Description: story.description,
      Rating: story.custom_rating || 0,
      RatingCount: story.rating_count || 0,
      Status: story.story_status,
      SubTitle: story.sub_title,
      ImageURL: story.url,
      PremiumStatus: story.premium ? "Premium" : "Free",
      FreeChapters: story.premium ? story.preview_chapter_count || 0 : "All",
    }));

    switch (exportFormat) {
      case "json":
        const jsonString = JSON.stringify(exportData, null, 2);
        const blob = new Blob([jsonString], { type: "application/json" });
        saveAs(blob, "stories.json");
        break;

      case "csv":
        // CSVLink komponenti otomatik olarak indirme işlemini yapacak
        document.getElementById("csvDownload").click();
        break;

      case "excel":
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Stories");
        XLSX.writeFile(wb, "stories.xlsx");
        break;

      default:
        break;
    }
    setShowExportModal(false);
  };

  return (
    <MainLayout title="Stories" actionHeader={headerAction}>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <Form.Control
          type="text"
          placeholder="Search by title..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }}
        />
        <div className="d-flex gap-3">
          <div
            style={{
              background: "#f8f9fa",
              padding: "10px 15px",
              borderRadius: "8px",
              border: "1px solid #dee2e6",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <span style={{ fontWeight: "bold", color: "#495057" }}>
              Total Stories:
            </span>
            <span
              style={{
                marginLeft: "8px",
                fontSize: "1.1em",
                color: "#0d6efd",
                fontWeight: "500",
              }}
            >
              {stories.length}
            </span>
          </div>

          <Button
            variant="success"
            onClick={() => setShowExportModal(true)}
            className="me-2"
          >
            Export Stories
          </Button>
        </div>
      </div>

      <div className="">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th
                onClick={() => handleSort("id")}
                style={{ cursor: "pointer" }}
              >
                ID{" "}
                {sortField === "id"
                  ? sortDirection === "asc"
                    ? "↑"
                    : "↓"
                  : "↕"}
              </th>
              <th>Image</th>
              <th
                onClick={() => handleSort("title")}
                style={{ cursor: "pointer" }}
              >
                Title{" "}
                {sortField === "title"
                  ? sortDirection === "asc"
                    ? "↑"
                    : "↓"
                  : "↕"}
              </th>
              <th>Description</th>
              <th
                onClick={() => handleSort("custom_rating")}
                style={{ cursor: "pointer" }}
              >
                Rating{" "}
                {sortField === "custom_rating"
                  ? sortDirection === "asc"
                    ? "↑"
                    : "↓"
                  : "↕"}
              </th>
              <th
                onClick={() => handleSort("rating_count")}
                style={{ cursor: "pointer" }}
              >
                Rating Count{" "}
                {sortField === "rating_count"
                  ? sortDirection === "asc"
                    ? "↑"
                    : "↓"
                  : "↕"}
              </th>
              <th>Premium Status</th>
              <th>Free Chapters</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getSortedAndFilteredStories().map((story) => (
              <tr key={story.id}>
                <td>{story.id}</td>
                <td>{imageFormatter(null, story)}</td>
                <td>{story.title}</td>
                <td>
                  <div
                    dangerouslySetInnerHTML={{ __html: story.description }}
                    style={{
                      maxHeight: "150px",
                      overflow: "auto",
                      whiteSpace: "pre-wrap",
                      lineHeight: "1.5",
                    }}
                  />
                </td>
                <td>{story.custom_rating || 0}</td>
                <td>{story.rating_count}</td>
                <td>
                  <span
                    className={`badge ${
                      story.premium ? "bg-warning" : "bg-info"
                    }`}
                    style={{
                      padding: "6px 12px",
                      borderRadius: "4px",
                      fontWeight: "500",
                      color: story.premium ? "#000" : "#fff",
                    }}
                  >
                    {story.premium ? "Premium" : "Free"}
                  </span>
                </td>
                <td>
                  {story.premium
                    ? `${story.preview_chapter_count || 0} Free`
                    : "All Free"}
                </td>
                <td>
                  {actionFormatter(story.id, showDelete, handleEdit, navigate)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfirmation
        show={showDlt}
        handleClose={handleCloseDlt}
        handleDelete={handleDelete}
        title="Are you sure you want to delete this Story?"
      />
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Image URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <input
              type="text"
              value={selectedImageUrl}
              readOnly
              className="form-control"
            />
            <Button
              variant="outline-primary"
              className="ml-2"
              onClick={() => {
                navigator.clipboard.writeText(selectedImageUrl);
                toast.success("URL copied to clipboard!");
              }}
            >
              Copy
            </Button>
          </div>
          <div className="mt-3 text-center">
            <Image
              src={selectedImageUrl}
              style={{ maxWidth: "100%", maxHeight: "300px" }}
              alt="Selected story cover"
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Export Modal */}
      <Modal show={showExportModal} onHide={() => setShowExportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Export Stories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            className="mb-3"
            value={exportFormat}
            onChange={(e) => setExportFormat(e.target.value)}
          >
            <option value="json">JSON</option>
            <option value="csv">CSV</option>
            <option value="excel">Excel</option>
          </Form.Select>
          <div className="d-grid">
            <Button variant="primary" onClick={handleExport}>
              Download
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Gizli CSV indirme linki */}
      <CSVLink
        id="csvDownload"
        data={stories.map((story) => ({
          ID: story.id,
          Title: story.title,
          Description: story.description,
          Rating: story.custom_rating || 0,
          RatingCount: story.rating_count || 0,
          Status: story.story_status,
          SubTitle: story.sub_title,
          ImageURL: story.url,
          PremiumStatus: story.premium ? "Premium" : "Free",
          FreeChapters: story.premium
            ? story.preview_chapter_count || 0
            : "All",
        }))}
        filename="stories.csv"
        style={{ display: "none" }}
      />
    </MainLayout>
  );
};

export default Stories;
