import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav } from "react-bootstrap";
import TutorialPanel from "../tutorial/TutorialPanel";

const NavBar = ({ title, toggle, actionHeader, onTutorialToggle }) => {
  const [showTutorial, setShowTutorial] = useState(false);

  const handleTutorialToggle = (isVisible) => {
    setShowTutorial(isVisible);
    onTutorialToggle?.(isVisible);
  };

  return (
    <>
      <Navbar
        bg="light"
        className="navbar shadow-sm p-3 mb-5 bg-white rounded"
        expand
        style={{ height: "60px" }}
      >
        <Button variant="outline-info" onClick={toggle}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto d-flex content-between w-100" navbar>
            <div className="d-flex align-items-center">
              <span
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  color: "#333",
                  paddingLeft: "10px",
                }}
              >
                {title}
              </span>
            </div>
            <div className="d-flex align-items-center gap-3">
              {typeof actionHeader === "function"
                ? actionHeader()
                : actionHeader}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {!showTutorial && (
        <div
          style={{
            position: "fixed",
            bottom: "100px",
            right: "40px",
            zIndex: 1040,
          }}
        >
          <Button
            onClick={() => handleTutorialToggle(true)}
            style={{
              background: "#495057",
              color: "white",
              border: "none",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              borderRadius: "20px",
              padding: "8px 16px",
              transition: "all 0.2s ease",
            }}
            onMouseEnter={(e) => {
              e.target.style.background = "#343a40";
              e.target.style.transform = "translateY(-1px)";
              e.target.style.boxShadow = "0 4px 6px rgba(0,0,0,0.15)";
            }}
            onMouseLeave={(e) => {
              e.target.style.background = "#495057";
              e.target.style.transform = "translateY(0)";
              e.target.style.boxShadow = "0 2px 4px rgba(0,0,0,0.1)";
            }}
          >
            <i className="fas fa-question-circle me-2"></i>
            Help & Tools
          </Button>
        </div>
      )}

      <TutorialPanel
        isOpen={showTutorial}
        onClose={() => handleTutorialToggle(false)}
      />
    </>
  );
};

export default NavBar;
