import React, { useEffect, useState, useRef, Suspense } from "react";
import MainLayout from "../MainLayout";
import {
  Button,
  Accordion,
  Card,
  Badge,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import {
  deleteChapter,
  getChapterList,
  getChaptersList,
  getStories,
} from "../../utils/endpoints";
import DeleteConfirmation from "../modalView/deleteConfirmation";
import CreateChapter from "./createChapter";
import { toast } from "react-toastify";
import { useSearchParams, useLocation } from "react-router-dom";

// ReactMarkdown'ı lazy loading ile import edelim
const ReactMarkdown = React.lazy(() => import("react-markdown"));

// QuickViewModal'ı güncelleyelim
const QuickViewModal = React.memo(({ content, chapter, onClose, onEdit }) => {
  return (
    <div className="quick-view-modal" onClick={onClose}>
      <div className="quick-view-content" onClick={(e) => e.stopPropagation()}>
        <div className="quick-view-header">
          <div className="d-flex align-items-center gap-3">
            <h5 style={{ margin: 0 }}>Chapter Content</h5>
          </div>
          <div>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => onEdit(chapter)}
            >
              Edit Chapter
            </Button>
            <button onClick={onClose} className="close-button">
              ×
            </button>
          </div>
        </div>
        <div className="quick-view-body">
          <div
            style={{
              whiteSpace: "pre-wrap",
              fontFamily: "inherit",
              fontSize: "inherit",
              lineHeight: "1.6",
            }}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </div>
    </div>
  );
});

// LoadingIndicator'ı component dışına taşıyalım
const LoadingIndicator = () => (
  <div className="custom-loader-wrapper">
    <div className="custom-loader">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
);

const Chapters = () => {
  const [chapters, setChapters] = useState([]);
  const [stories, setStories] = useState([]);
  const [show, setShow] = useState(false);
  const [showDlt, setShowDlt] = useState(false);
  const [selectChapter, setSelectChapter] = useState(null);
  const [chapterToDelete, setChapterToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStory, setSelectedStory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [storySearchTerm, setStorySearchTerm] = useState("");
  const [searchParams] = useSearchParams();
  const urlStoryId = searchParams.get("storyId");
  const [loading, setLoading] = useState(true);
  const [contentVisible, setContentVisible] = useState(false);
  const [accordionReady, setAccordionReady] = useState(false);
  const [modalState, setModalState] = useState({
    isVisible: false,
    content: "",
  });
  const [showExportModal, setShowExportModal] = useState(false);
  const [searchIn, setSearchIn] = useState("title"); // 'title' veya 'all'
  const [sortOption, setSortOption] = useState("title-asc"); // 'title-asc', 'title-desc', 'chapters-asc', 'chapters-desc'
  const location = useLocation();
  const {
    searchTerm: locationSearchTerm,
    isNewStory,
    storyTitle,
  } = location.state || {};

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (urlStoryId) {
      // URL'den gelen story ID varsa, o story'nin accordion'unu aç
      const story = stories.find((s) => s.id === parseInt(urlStoryId));
      if (story) {
        // Biraz gecikmeyle scroll yapalım ki içerik yüklensin
        setTimeout(() => {
          const element = document.querySelector(
            `[data-story-id="${urlStoryId}"]`
          );
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
            // Highlight effect ekleyelim
            element.style.transition = "background-color 0.5s ease";
            element.style.backgroundColor = "#e9ecef";
            setTimeout(() => {
              element.style.backgroundColor = "transparent";
            }, 1500);
          }
        }, 100);
      }
    }
  }, [urlStoryId, stories]);

  useEffect(() => {
    // Eğer yeni story oluşturulduysa bildirim göster
    if (isNewStory && storyTitle) {
      toast.info(`Showing chapters for "${storyTitle}"`, {
        position: "top-right",
        autoClose: 3000,
      });

      // Search input'a story title'ı otomatik set et
      if (locationSearchTerm) {
        setSearchTerm(locationSearchTerm);
      }
    }
  }, [isNewStory, storyTitle, locationSearchTerm]);

  const loadData = async () => {
    try {
      // Paralel veri çekme
      const [chaptersResponse, storiesResponse] = await Promise.all([
        getChaptersList(),
        getStories(),
      ]);

      if (chaptersResponse?.data && storiesResponse?.data) {
        // Chapters'ı grupla
        const groupedChapters = chaptersResponse.data.reduce((acc, chapter) => {
          if (!acc[chapter.story_id]) {
            acc[chapter.story_id] = [];
          }
          acc[chapter.story_id].push(chapter);
          return acc;
        }, {});

        setChapters(groupedChapters);
        setStories(storiesResponse.data);
        setLoading(false);
        setContentVisible(true);
        setAccordionReady(true);
      }
    } catch (error) {
      toast.error("Failed to load data");
      setLoading(false);
    }
  };

  const handleEdit = (chapter) => {
    setSelectChapter(chapter);
    setShow(true);
  };

  const handleDelete = async () => {
    try {
      const { error } = await deleteChapter(chapterToDelete.id);
      if (error) throw error;

      toast.success("Chapter deleted successfully!");
      setShowDlt(false);
      setChapterToDelete(null);
      loadData(); // Listeyi yenile
    } catch (error) {
      toast.error("Failed to delete chapter");
      setShowDlt(false);
      setChapterToDelete(null);
    }
  };

  const handleCreate = () => {
    setSelectChapter(null);
    setShow(true);
  };

  const handleAddChapter = (storyId) => {
    setSelectedStory(storyId);
    setSelectChapter(null);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectChapter(null);
    setSelectedStory(null); // Modal kapanınca selected story'i sıfırla
  };

  const getFilteredStories = () => {
    const filtered = stories.filter((story) => {
      const searchLower = searchTerm.toLowerCase();
      const matchesTitle = story.title.toLowerCase().includes(searchLower);
      const hasChapters = chapters[story.id]?.length > 0;

      return (searchTerm ? matchesTitle : true) && hasChapters;
    });

    // Sıralama uygula
    return filtered.sort((a, b) => {
      const aChapters = chapters[a.id]?.length || 0;
      const bChapters = chapters[b.id]?.length || 0;

      switch (sortOption) {
        case "title-asc":
          return a.title.localeCompare(b.title);
        case "title-desc":
          return b.title.localeCompare(a.title);
        case "chapters-asc":
          return aChapters - bChapters;
        case "chapters-desc":
          return bChapters - aChapters;
        default:
          return 0;
      }
    });
  };

  // handleExport fonksiyonunu güncelleyelim
  const handleExport = () => {
    const allChapters = Object.values(chapters).flat();
    const chaptersWithStoryNames = allChapters.map((chapter) => {
      const story = stories.find((s) => s.id === chapter.story_id);
      return {
        story_title: story?.title || "Unknown Story",
        chapter_name: chapter.chapter_name,
        position: chapter.position,
        content: chapter.content,
      };
    });

    // JSON export
    const jsonExport = () => {
      const blob = new Blob([JSON.stringify(chaptersWithStoryNames, null, 2)], {
        type: "application/json",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "chapters_export.json";
      link.click();
    };

    // CSV export
    const csvExport = () => {
      const csv = [
        ["Story Title", "Chapter Name", "Position", "Content"],
        ...chaptersWithStoryNames.map((ch) => [
          String(ch.story_title || ""),
          String(ch.chapter_name || ""),
          String(ch.position || ""),
          String(ch.content || ""),
        ]),
      ]
        .map((row) =>
          row.map((cell) => `"${String(cell).replace(/"/g, '""')}"`).join(",")
        )
        .join("\n");

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "chapters_export.csv";
      link.click();
    };

    // Excel export
    const excelExport = async () => {
      const XLSX = await import("xlsx");
      const worksheet = XLSX.utils.json_to_sheet(chaptersWithStoryNames);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Chapters");
      XLSX.writeFile(workbook, "chapters_export.xlsx");
    };

    // Export butonlarını Modal ile göster
    return (
      <Modal show={true} onHide={() => setShowExportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Export Chapters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-3 justify-content-center">
            <Button variant="primary" onClick={jsonExport}>
              Export as JSON
            </Button>
            <Button variant="success" onClick={csvExport}>
              Export as CSV
            </Button>
            <Button variant="warning" onClick={excelExport}>
              Export as Excel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <MainLayout title="Chapters" className={loading ? "loading" : ""}>
      {loading && <LoadingIndicator />}
      <div
        className={`chapters-container ${accordionReady ? "data-ready" : ""}`}
      >
        <div className={`content-wrapper ${contentVisible ? "fade-in" : ""}`}>
          <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap gap-3">
              <div className="d-flex gap-2">
                <Button variant="primary" onClick={handleCreate}>
                  Create Chapter
                </Button>
                <Button
                  variant="outline-success"
                  onClick={() => setShowExportModal(true)}
                >
                  Export Chapters
                </Button>
              </div>

              <div className="d-flex flex-wrap gap-2 align-items-center">
                <Form.Control
                  type="text"
                  placeholder="Search stories..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: "200px", minWidth: "150px" }}
                />

                <Form.Select
                  value={sortOption}
                  onChange={(e) => setSortOption(e.target.value)}
                  style={{ width: "150px", minWidth: "120px" }}
                >
                  <option value="title-asc">Title (A-Z)</option>
                  <option value="title-desc">Title (Z-A)</option>
                  <option value="chapters-asc">Chapters ↑</option>
                  <option value="chapters-desc">Chapters ↓</option>
                </Form.Select>

                <div className="d-flex gap-2 flex-wrap">
                  <div
                    style={{
                      background: "#f8f9fa",
                      padding: "8px 12px",
                      borderRadius: "8px",
                      border: "1px solid #dee2e6",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                      minWidth: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <span
                      style={{
                        color: "#495057",
                        whiteSpace: "nowrap",
                        fontSize: "0.9rem",
                      }}
                    >
                      Stories:
                    </span>
                    <span
                      style={{
                        color: "#0d6efd",
                        fontWeight: "500",
                        fontSize: "0.95rem",
                      }}
                    >
                      {Object.keys(chapters).length}
                    </span>
                  </div>

                  <div
                    style={{
                      background: "#f8f9fa",
                      padding: "8px 12px",
                      borderRadius: "8px",
                      border: "1px solid #dee2e6",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                      minWidth: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <span
                      style={{
                        color: "#495057",
                        whiteSpace: "nowrap",
                        fontSize: "0.9rem",
                      }}
                    >
                      Chapters:
                    </span>
                    <span
                      style={{
                        color: "#0d6efd",
                        fontWeight: "500",
                        fontSize: "0.95rem",
                      }}
                    >
                      {Object.values(chapters).flat().length}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Story to Scroll</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <Form.Control
                    type="text"
                    placeholder="Search stories..."
                    value={storySearchTerm}
                    onChange={(e) => setStorySearchTerm(e.target.value)}
                    style={{ width: "60%" }}
                  />
                  <div className="d-flex gap-3">
                    <div
                      style={{
                        background: "#f8f9fa",
                        padding: "10px 15px",
                        borderRadius: "8px",
                        whiteSpace: "nowrap",
                        border: "1px solid #dee2e6",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        marginLeft: "20px",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#495057" }}>
                        Total Stories:
                      </span>
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "1.1em",
                          color: "#0d6efd",
                          fontWeight: "500",
                        }}
                      >
                        {stories.length}
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
                  <Table striped bordered hover>
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stories
                        .filter(
                          (story) =>
                            story.title
                              .toLowerCase()
                              .includes(storySearchTerm.toLowerCase()) ||
                            story.description
                              ?.toLowerCase()
                              .includes(storySearchTerm.toLowerCase())
                        )
                        .map((story) => (
                          <tr key={story.id}>
                            <td>{story.title}</td>
                            <td>{story.description}</td>
                            <td>
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                  setSelectedStory(story.id);
                                  setShow(true);
                                  setShowModal(false);
                                }}
                              >
                                Select
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
            </Modal>

            <Accordion
              defaultActiveKey={urlStoryId?.toString()}
              className={accordionReady ? "ready" : ""}
            >
              {getFilteredStories().map((story) => {
                const storyChapters = chapters[story.id] || [];
                if (storyChapters.length === 0) return null;

                return (
                  <Card key={story.id}>
                    <Accordion.Item
                      eventKey={story.id.toString()}
                      data-story-id={story.id}
                      style={{ transition: "background-color 0.5s ease" }}
                    >
                      <Accordion.Header>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="d-flex align-items-center gap-3">
                            <span className="fw-bold">{story.title}</span>
                            <Badge bg="primary" pill>
                              {storyChapters.length} chapters
                            </Badge>
                          </div>
                          <div
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginLeft: "auto" }}
                          >
                            <span
                              className="btn btn-outline-primary btn-sm"
                              role="button"
                              onClick={() => handleAddChapter(story.id)}
                            >
                              Add Chapter
                            </span>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Position</th>
                                <th>Chapter Name</th>
                                <th>Content</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {storyChapters
                                .sort((a, b) => a.position - b.position)
                                .map((chapter) => (
                                  <tr key={chapter.id}>
                                    <td>{chapter.position}</td>
                                    <td>{chapter.chapter_name}</td>
                                    <td>
                                      <div
                                        style={{
                                          maxHeight: "75px",
                                          overflow: "hidden",
                                          position: "relative",
                                          whiteSpace: "pre-wrap",
                                          fontFamily: "inherit",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {/* İlk 200 karakteri göster */}
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              chapter.content.slice(0, 200) +
                                              (chapter.content.length > 200
                                                ? "..."
                                                : ""),
                                          }}
                                        />

                                        {/* Gradient overlay */}
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            height: "25px",
                                            background:
                                              "linear-gradient(transparent, white)",
                                          }}
                                        />
                                      </div>

                                      <div
                                        onClick={() => {
                                          const startTime = performance.now();
                                          setModalState({
                                            isVisible: true,
                                            content: chapter.content,
                                            chapter: chapter,
                                          });
                                          requestAnimationFrame(() => {
                                            const endTime = performance.now();
                                            console.log(
                                              `Modal açılış toplam süresi: ${
                                                endTime - startTime
                                              }ms`
                                            );
                                          });
                                        }}
                                        style={{
                                          color: "#0d6efd",
                                          cursor: "pointer",
                                          fontSize: "0.9em",
                                          marginTop: "5px",
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "5px",
                                        }}
                                      >
                                        <i className="fas fa-eye"></i>
                                        <span>View Content</span>
                                      </div>
                                    </td>
                                    <td>
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        className="me-2"
                                        onClick={() => handleEdit(chapter)}
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => {
                                          setChapterToDelete(chapter);
                                          setShowDlt(true);
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>

      <CreateChapter
        show={show}
        handleClose={handleClose}
        obj={selectChapter}
        reload={loadData}
        storyId={selectedStory}
      />
      <DeleteConfirmation
        show={showDlt}
        handleClose={() => {
          setShowDlt(false);
          setChapterToDelete(null);
        }}
        handleDelete={handleDelete}
        title={`Are you sure you want to delete "${chapterToDelete?.chapter_name}" chapter?`}
      />
      {modalState.isVisible && (
        <QuickViewModal
          content={modalState.content}
          chapter={modalState.chapter}
          onClose={() =>
            setModalState({ isVisible: false, content: "", chapter: null })
          }
          onEdit={(chapter) => {
            setModalState({ isVisible: false, content: "", chapter: null });
            handleEdit(chapter);
          }}
        />
      )}
      {showExportModal && handleExport()}
    </MainLayout>
  );
};

// Stil ekleyelim
const styles = `
  .chapters-container {
    min-height: 500px;
    position: relative;
  }

  .content-wrapper {
    opacity: 1;
  }

  .accordion-item {
    border: 1px solid rgba(0,0,0,.125);
  }

  .accordion-body {
    padding: 1rem;
  }

  .custom-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    z-index: 9999;
  }

  .quick-view-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quick-view-content {
    background: white;
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    border-radius: 8px;
    overflow: hidden;
  }

  .quick-view-header {
    padding: 15px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
  }

  .quick-view-body {
    padding: 20px;
    max-height: calc(90vh - 70px);
    overflow-y: auto;
    position: relative;
  }

  .close-button {
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0 5px;
  }
`;

// Stili head'e ekleyelim
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Chapters;
