import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button, Form as BootstrapForm, Container, Row, Col } from 'react-bootstrap';
import MainLayout from '../MainLayout';
import { createUser } from '../../utils/endpoints';

const CreateAccount = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, 'Name must be at least 2 characters')
      .required('Name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const onSubmit = (values) => {
    const { name, email, password } = values;
    setIsSubmitting(true);
    createUser({name: name, email: email, password: password}).then((data) => {
      if (data.data) {
        toast.success('Account created successfully');
        localStorage.setItem('authToken', JSON.stringify(data.data));
        navigate('/');
      } else {
        setIsSubmitting(false);
        toast.error('Account creation failed');
      }
    });
  };

  return (
    <MainLayout title="Create Account">
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="w-100">
          <Col md={{ span: 4, offset: 4 }}>
            <h2 className="text-center mb-4">Create Account</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors }) => (
                <Form>
                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Name</BootstrapForm.Label>
                    <Field
                      name="name"
                      type="text"
                      as={BootstrapForm.Control}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <ErrorMessage name="name" component={BootstrapForm.Control.Feedback} type="invalid" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Email</BootstrapForm.Label>
                    <Field
                      name="email"
                      type="text"
                      as={BootstrapForm.Control}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <ErrorMessage name="email" component={BootstrapForm.Control.Feedback} type="invalid" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Password</BootstrapForm.Label>
                    <Field
                      name="password"
                      type="password"
                      as={BootstrapForm.Control}
                      isInvalid={touched.password && !!errors.password}
                    />
                    <ErrorMessage name="password" component={BootstrapForm.Control.Feedback} type="invalid" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Confirm Password</BootstrapForm.Label>
                    <Field
                      name="confirmPassword"
                      type="password"
                      as={BootstrapForm.Control}
                      isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                    />
                    <ErrorMessage name="confirmPassword" component={BootstrapForm.Control.Feedback} type="invalid" />
                  </BootstrapForm.Group>

                  <Button variant="primary" type="submit" disabled={isSubmitting} className="w-100">
                    {isSubmitting ? 'Creating account...' : 'Create Account'}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default CreateAccount;
