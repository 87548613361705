import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import {
  Form as BootstrapForm,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
export const TextField = ({ label, ...props }) => {
  return (
    <div className="form-group">
      <label className="form-title">{label}</label>
      <Field className="form-control" {...props} onInput={props.onInput} />
      <ErrorMessage name={props.name} component="div" className="text-danger" />
    </div>
  );
};

export const SelectField = ({ options, name, label, main }) => {
  const { values, setFieldValue } = useFormikContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const filteredOptions = options?.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelect = (value) => {
    setFieldValue(name, value);
    main &&
      setFieldValue(
        "sub_tag",
        values["sub_tag"].filter((item) => item != value)
      );
    setShowDropdown(false);
  };

  const selectedLabel =
    options?.find((opt) => opt.value === values[name])?.label || "Select";

  return (
    <BootstrapForm.Group controlId={name}>
      <BootstrapForm.Label className="form-title">{label}</BootstrapForm.Label>
      <Dropdown
        show={showDropdown}
        onToggle={(isOpen) => setShowDropdown(isOpen)}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          className="w-100 text-start"
          style={{
            color: values[name] ? "#000" : "#6c757d",
            fontWeight: values[name] ? "500" : "normal",
          }}
        >
          {selectedLabel}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          <Form.Group className="px-2">
            <BootstrapForm.Control
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </Form.Group>
          <div className="select-box">
            <Dropdown.Item onClick={() => handleSelect("")}>
              Select
            </Dropdown.Item>
            {filteredOptions?.map((item, index) => (
              <div key={index}>
                {item.image ? (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-${index}`}>
                        <img
                          src={item.image}
                          alt={item.label}
                          style={{
                            width: item.width || "200px",
                            height: item.width
                              ? `${item.width * item.aspectRatio}px`
                              : "200px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          }}
                        />
                      </Tooltip>
                    }
                  >
                    <Dropdown.Item
                      onClick={() => handleSelect(item.value)}
                      active={values[name] === item.value}
                    >
                      {item.label}
                    </Dropdown.Item>
                  </OverlayTrigger>
                ) : (
                  <Dropdown.Item
                    onClick={() => handleSelect(item.value)}
                    active={values[name] === item.value}
                  >
                    {item.label}
                  </Dropdown.Item>
                )}
              </div>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <ErrorMessage name={name} component="div" className="text-danger" />
    </BootstrapForm.Group>
  );
};

export const MultiSelectField = ({ name, options, label, mainId = null }) => {
  const { values, setFieldValue } = useFormikContext();
  const [searchQuery, setSearchQuery] = useState("");

  const handleOptionChange = (option) => {
    const selectedOptions = values[name] || [];
    if (selectedOptions.includes(option)) {
      setFieldValue(
        name,
        selectedOptions.filter((selectedOption) => selectedOption !== option)
      );
    } else {
      setFieldValue(name, [...selectedOptions, option]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <BootstrapForm.Group controlId={name}>
        <BootstrapForm.Label className="form-title">
          {label}
        </BootstrapForm.Label>
        <Dropdown>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
            {label}
          </Dropdown.Toggle>
          <Dropdown.Menu className="pt-0">
            <Form.Group>
              <BootstrapForm.Control
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <div className="select-box">
                {filteredOptions.map((option, index) => (
                  <BootstrapForm.Check
                    key={`index-opt-${index}`}
                    type="checkbox"
                    label={option.label}
                    checked={(values[name] || []).includes(option.value)}
                    onChange={() => handleOptionChange(option.value)}
                    disabled={option.value == mainId}
                  />
                ))}
              </div>
            </Form.Group>
          </Dropdown.Menu>
        </Dropdown>
        <ErrorMessage name={name} component="div" className="text-danger" />
      </BootstrapForm.Group>

      <div className="selected-options">
        {(values[name] || []).map((selectedValue) => {
          const selectedOption = options.find(
            (option) => option.value === selectedValue
          );
          return (
            <span key={selectedValue} className="selected-tag">
              {selectedOption?.label}
              <button
                type="button"
                className="remove-btn"
                onClick={() => handleOptionChange(selectedValue)}
              >
                &times;
              </button>
            </span>
          );
        })}
      </div>
    </div>
  );
};
