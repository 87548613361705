import React from "react";
import { Button } from "react-bootstrap";

const actionFormatter = (row, showDelete, handleEdit) => {
  return (
    <div>
      <Button
        variant="primary"
        size="sm"
        className="mr-2"
        onClick={() => handleEdit(row)}
      >
        Edit
      </Button>
      <Button variant="danger" size="sm" onClick={() => showDelete(row)}>
        Delete
      </Button>
    </div>
  );
};

export default actionFormatter;
