import React, { useEffect, useState, useMemo } from "react";
import { Formik, Form } from "formik";
import { Container, Row, Button } from "react-bootstrap";
import * as Yup from "yup";
import { SelectField, TextField } from "../formFields/Fields";
import ModalView from "../modalView";
import { createSection, updateSection } from "../../utils/endpoints";
import { toast } from "react-toastify";
import verticalImage from "../images/vertical.jpg";
import cardImage from "../images/card.jpg";
import horizontalImage from "../images/horizontal.jpg";

const validationSchema = Yup.object({
  section_name: Yup.string().required("Section Name is required"),
  view_types: Yup.mixed().required("View Type is required"),
});

const CreateSection = ({ show, handleClose, obj = null, reload }) => {
  const [initialValues, setInitialValue] = useState({
    section_name: "",
    view_types: "",
  });

  const viewList = useMemo(
    () => [
      {
        value: "horizontal",
        label: "Horizontal",
        image: horizontalImage,
        aspectRatio: 490 / 828,
        width: 200,
      },
      {
        value: "vertical",
        label: "Vertical",
        image: verticalImage,
        aspectRatio: 471 / 828,
        width: 200,
      },
      { value: "tag", label: "Tag" },
      {
        value: "card",
        label: "Card",
        image: cardImage,
        aspectRatio: 1262 / 828,
        width: 200,
      },
    ],
    []
  );

  useEffect(() => {
    if (obj) {
      setInitialValue({
        section_name: obj.section_name || "",
        view_types: obj.view_types || "",
      });
    } else {
      setInitialValue({ section_name: "", view_types: "" });
    }
  }, [obj]);

  const handleSubmit = async (values) => {
    try {
      if (obj) {
        const data = await updateSection(values, obj.id);
        if (data.error) {
          toast.error(data.error.message);
        } else {
          toast.success("Section Updated!");
        }
      } else {
        const data = await createSection(values);
        if (data.error) {
          toast.error(data.error.message);
        } else {
          toast.success("Section Created!");
        }
      }
      reload();
      handleClose();
    } catch {
      toast.error(`Failed ${obj ? "Updation" : "Creation"} of Section!`);
    }
  };

  return (
    <ModalView
      show={show}
      handleClose={handleClose}
      title={obj ? "Update Section" : "Create Section"}
    >
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <Form>
            <Row>
              <TextField name="section_name" label="Section Name" />
            </Row>
            <Row className="mt-20">
              <SelectField
                name="view_types"
                label="View Types"
                options={viewList}
              />
            </Row>
            <div className="float-right mt-20 d-flex">
              <div className="mr-10">
                <Button onClick={handleClose} variant="secondary">
                  Cancel
                </Button>
              </div>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </div>
          </Form>
        </Formik>
      </Container>
    </ModalView>
  );
};

export default CreateSection;
