import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  Button,
  Form as BootstrapForm,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import MainLayout from "../MainLayout";
import { getLoginDetails } from "../../utils/endpoints";

const Login = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    username: "",
    password: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = (values) => {
    const { username, password } = values;
    setIsSubmitting(true);
    getLoginDetails(username, password).then((data) => {
      if (data.data) {
        toast.success("Login successful");
        localStorage.setItem("authToken", JSON.stringify(data.data));
        navigate("/");
      } else {
        setIsSubmitting(false);
        toast.error("Invalid credentials");
      }
    });
  };

  return (
    <MainLayout title="Login">
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="w-100">
          <Col md={{ span: 4, offset: 4 }}>
            <h2 className="text-center mb-4">Login</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors, setFieldValue }) => (
                <Form>
                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Email</BootstrapForm.Label>
                    <Field
                      name="username"
                      type="text"
                      as={BootstrapForm.Control}
                      isInvalid={touched.username && !!errors.username}
                    />
                    <ErrorMessage
                      name="username"
                      component={BootstrapForm.Control.Feedback}
                      type="invalid"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Password</BootstrapForm.Label>
                    <Field
                      name="password"
                      type="password"
                      as={BootstrapForm.Control}
                      isInvalid={touched.password && !!errors.password}
                    />
                    <ErrorMessage
                      name="password"
                      component={BootstrapForm.Control.Feedback}
                      type="invalid"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group
                    className="mb-3"
                    controlId="formBasicCheckbox"
                  >
                    <BootstrapForm.Check
                      type="checkbox"
                      label="Remember Me"
                      onChange={(e) =>
                        setFieldValue("rememberMe", e.target.checked)
                      }
                    />
                  </BootstrapForm.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    className="w-100"
                  >
                    {isSubmitting ? "Logging in..." : "Login"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default Login;
