import { Button, Modal } from "react-bootstrap";

const ModalView = ({show, handleClose, children, title}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton className="bg-primary color-white">
        <Modal.Title>{title || "Modal"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
export default ModalView;
